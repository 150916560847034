const React = require("react")
const Sentry = require("@sentry/browser")
const { Integrations } = require("@sentry/tracing")
const {
  CaptureConsole,
  Dedupe,
  ExtraErrorData,
  Offline,
  ReportingObserver,
} = require("@sentry/integrations")
const Layout = require("./src/components/layout").default
var pjson = require("./package.json")
require("./src/styles.css")

exports.onClientEntry = () => {
  window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, "")
  })
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment:
      process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development",
    release: `${pjson.name}@${pjson.version} [${process.env.GATSBY_GIT_SHA}]`,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ["log", "warn", "error", "debug", "assert"],
      }),
      new Dedupe(),
      new ExtraErrorData({ depth: 5 }),
      new Offline(),
      new ReportingObserver(),
    ],
    tracesSampleRate: 1.0,
  })
}

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
